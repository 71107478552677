<!--园区动态 新闻 最新资讯-->
<template>
  <div class="servewrap">
    <!-- 顶部 -->
    <headbox></headbox>
    <!-- 滑块组件 -->
    <banner positionSid="2332"></banner>
    <!--热点资讯-->
    <informationNews></informationNews>
    <!-- 侧边导航 -->
    <sidebar ref="mysiderbar"></sidebar>
    <!-- 底部 -->
    <foot></foot>
  </div>
</template>

<script>
// import "swiper/swiper-bundle.css";
import headbox from "@/components/head";
import foot from "@/components/foot";
import sidebar from "@/components/sidebar";
import banner from "@/components/banner";
import informationNews from "@/components/informationNews.vue";

export default {
  components: {
    informationNews,
    banner,
    headbox,
    foot,
    sidebar,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  beforeMount() {
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style scoped>
.servewrap {
  font-size: 12px;
}
</style>
